body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card-body .col {
  display: flex;
  align-items: center;
  column-gap: 0.7em;
}

.accordion-body {
  border-left: 2px solid
}

.accordion-button {
  column-gap: 1em;
}

.leaflet-container {
  width: 100%;
  height: 100vh;
}
