@import "variables";
@import "~bootstrap/scss/bootstrap";

/** Extra CSS from Bidir, styles will be move in Birdz-React-Library **/
$sidebar-width: calc(100% - 260px) !default;
$sidebar-mini-width: calc(100% - 80px) !default;

.page-link.active,
.active>.page-link {
    background-color: $primary
}

body {
    background-image: url("./bg-light.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    font-size: 15px;
}

h2 {
    font-size: 1.2rem !important;
}

h3 {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 350;
}

h4 {
    background: rgba(0, 0, 0, 0.1);
    margin: -10px -10px 10px -10px;
    border-radius: 4px 4px 0px 0px;
    padding: 10px 20px;
    font-size: 20px;
}

.wrapper {
    position: relative;
    top: 0;
    height: 100vh;

    &.wrapper-full-page {
        min-height: 100vh;
        height: auto;
    }
}

.main-panel {
    position: relative;
    float: right;
    width: $sidebar-width;
    background-color: rgba(255, 255, 255, 0.8);

    .navbar {
        .avatar {
            display: inline-block;
            width: 30px;
            height: 30px;
            vertical-align: middle;
            margin-right: 10px;
            margin-top: -10px;
            margin-bottom: -10px;
        }
    }
}

.page-content {
    padding: 10px;
}

.pagination {
    justify-content: flex-end;
}

.toaster {
    position: absolute;
    top: 20px;
    right: 20px;

    &.success {
        background-color: #d4edda;
        border-color: #c3e6cb;
    }

    &.error {
        background-color: #f8d7da;
        border-color: #f5c6cb;
    }
}

.block {
    background: #fcfcfc;
    padding: 10px;
    border: 1px solid #dee2e6;
    margin-bottom: 15px;
    border-radius: 5px;

    h2 {
        margin-bottom: 25px;
    }
}

.fileInput {
    position: relative;
    overflow: hidden;
    display: inline-block;

    input[type="file"] {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }
}

.link {
    cursor: pointer;
}

.link-underline {
    text-decoration: underline;
}

.actions a,
td a {
    color: #373a3c;
}

.actions {
    .link {
        span {
            margin-right: 0.5rem;
        }
    }
}

.lastLogin {
    display: block;
    position: absolute;
    right: 12px;
    margin-top: 11px;
    font-style: italic;
    font-size: 13px;
}

.avatar {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: white;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.btns {

    input[type="button"],
    input[type="submit"],
    button {
        padding: 6px 50px;
    }

    input[type="submit"],
    .btn-primary {
        margin-left: 10px;
    }
}

.modal-80w {
    max-width: 80%;
}

.clickable {
    &:hover {
        text-decoration: underline;
    }

    cursor: pointer;
}

.orderable {
    cursor: pointer;
}

.orderPosition {
    width: 16px;
    height: 16px;
    font-size: 10px;
}

.pageSize {
    display: inline-block;

    >div {
        z-index: 100;
        display: inline-block;
        width: 80px;
    }
}