$primary: #253053;

$theme-colors: (
    "primary": $primary,
    "danger": #b4241c
);
$border-radius: 0;

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container input {
    width: 100%;
    border-radius: 0.3rem !important;
    font-family: "Segoe UI", "Source Sans Pro", Calibri, Candara, Arial,
        sans-serif;
    padding-top: 7px;
    padding-right: 8px;
    padding-bottom: 7px;
    padding-left: 8px;
    background-color: #fff;
    outline-color: rgb(38, 132, 255);
    border: 1px solid rgb(204, 204, 204);
}

.react-datepicker-popper {
    min-width: 330px !important;
}