.main-panel {
    position: relative;
    float: right;
    width: calc(100% - 260px);
    background-color: rgba(255, 255, 255, 0.8);

    .navbar {
        padding-right: 15px;

        .avatar {
            display: inline-block;
            width: 30px;
            height: 30px;
            vertical-align: middle;
            margin-right: 10px;
            margin-top: -10px;
            margin-bottom: -10px;
        }
    }
                .last-login {
                    display: block;
                    position: absolute;
                    right: 12px;
                    margin-top: 11px;
                    font-style: italic;
                    font-size: 13px;
        
                }
        
                .page-content {
                    padding: 10px;

                    .table {
                        --bs-table-bg: white;
                    }
                }
}